<script>
import { mapActions, mapGetters } from 'vuex';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default {
    computed: mapGetters(['companys', "usersSameCompany", "user", "users"]),
    data() {
        return {
            usersCompanyProcenas: null,
            activeProcena: null,
            year: null,
            location: null,
            position: null,
            answers: [],
            savedSuccessfully: false,
            saveFailed: false,
            savedProcenaExists: false,
            saveFailedText: null,
            finished: false,
            total: null,
            totalPercent: null,
            selectedUser: null,
            selectedUserAllProcenas: [],
            selectedUserAllProcenasCanView: [],
            selectedProcenaName: null,
            selectedProcena: null,
            usersWithCustomProcena: [],
            usersWithCustomProcenaAndPermission: [],
            preparePdf: false,
        }
    },
    methods: {
        ...mapActions(['getCompanies', "getCollegues", "getProfile", 'customProcenaSaveAnswers']),
        generateProcena(procena) {
            this.usersCompanyProcenas.forEach((ele) => {
                if (ele.name == procena) {
                    this.activeProcena = ele
                    this.activeProcena.questions.forEach((element) => {
                        if (element.isSingleCheck) {
                            element.isChecked = [];
                            element.answers.forEach((answ) => {
                                element.isChecked.push({ checked: false, answer: answ })
                            })
                        } else if (element.isComboAnswer) {
                            element.isCombo = { text: null, isChecked: [] };
                            element.isCombo.text = null,
                                element.answers.forEach((answ) => {
                                    element.isCombo.isChecked.push({ checked: false, answer: answ })
                                })
                        }
                        else if (element.isTextAnswer) {
                            element.textAnswer = null;
                        }
                    })
                }
            });

            this.finished = false;
            this.selectedUserAllProcenasCanView.forEach(ele => {
                if (ele.name == procena) {
                    this.finished = ele.finished;
                    for (let i = 0; i < this.activeProcena.questions.length; i++) {
                        if (this.activeProcena.questions[i].isSingleCheck) {
                            this.activeProcena.questions[i].isChecked = ele.answers[i]

                        } else if (this.activeProcena.questions[i].isComboAnswer) {
                            this.activeProcena.questions[i].isCombo.isChecked = ele.answers[i].answers
                            this.activeProcena.questions[i].isCombo.text = ele.answers[i].text

                        } else if (this.activeProcena.questions[i].isTextAnswer) {
                            this.activeProcena.questions[i].textAnswer = ele.answers[i]
                        }
                    }
                }
            })
            this.getTotal();

        },
        makePDFProcena() {
            window.html2canvas = html2canvas;
            var doc = new jsPDF("l", "pt", "a4");
            doc.html(document.querySelector("#procena"), {
                callback: function (pdf) {
                    pdf.save("procena.pdf");
                }
            });
        },
        singleCheck(item, index) {
            for (let i = 0; i < item.length; i++) {
                if (index == i) {
                    item[i].checked = true;
                } else {
                    item[i].checked = false;
                }
            }
        },
        getTotal() {
            this.total = null;
            this.totalPercent = null;
            let hasNumber = null;
            this.activeProcena.questions.forEach((item) => {
                if (item.isComboAnswer) {
                    hasNumber += 1;
                    for (let i = 0; i < item.isCombo.isChecked.length; i++) {
                        if (item.isCombo.isChecked[i].checked) {
                            this.total += i + 1;
                        }
                    }
                } else if (item.isSingleCheck) {
                    hasNumber += 1;
                    for (let i = 0; i < item.isChecked.length; i++) {
                        if (item.isChecked[i].checked) {
                            this.total += i + 1;
                        }
                    }
                }

            })
            this.totalPercent = parseFloat((hasNumber / this.total) * 100).toFixed(2)
        },
        onChangeUsers() {
            this.activeProcena = null;
            this.selectedProcenaName = null;
            this.selectedUserAllProcenasCanView = [];
            this.usersWithCustomProcena.forEach(user => {
                if (user.name == this.selectedUser) {
                    this.selectedUserAllProcenas = user.customProcenaSchema.data
                }

            })
            this.selectedUserAllProcenas.forEach(procena => {
                this.user.availableTest.customProcenaView.forEach(canViewProcena => {
                    if (procena.name == canViewProcena) {
                        this.selectedUserAllProcenasCanView.push(procena)
                    }
                })
            })
            this.usersSameCompany.forEach(user => {
                if (this.selectedUser == user.name) {
                    this.year = user.customProcenaSchema.year;
                    this.position = user.customProcenaSchema.position;
                    this.location = user.customProcenaSchema.location;
                }
            })
            

        },
        onChangeProcena() {
            this.selectedUserAllProcenasCanView.forEach(ele => {
                if (this.selectedProcenaName == ele.name) {
                    this.selectedProcena = ele
                }
            })

            this.generateProcena(this.selectedProcenaName);
        }
    },
    async created() {
        await this.getProfile();
        await this.getCollegues(this.user.type);
        await this.getCompanies();
        this.usersSameCompany.forEach(user => {
            
            if (user.customProcenaSchema?.data.length > 0) {
                if(this.user.name == user.name || this.user.name == user.nadredjeni){
                this.usersWithCustomProcena.push(user)
            }
            }
        })
        this.companys.forEach(comp => {
            if (this.user.type == comp.name) {
                this.usersCompanyProcenas = comp.customProcena
            }
        })


    }
}

</script>
<template>
    <div class="glavni">
        <div class="container">
            <div class="">
                <img src="@/assets/logonewest.svg" alt="" id="logo">
            </div>
            <div class="form-group">
                <label for="collegue">Zaposleni: </label>
                <select name="collegue" id="collegue" v-model="selectedUser" @change="onChangeUsers()">
                    <option v-for="user in usersWithCustomProcena" :key="user.name">{{ user.name }}</option>
                </select>
                <div v-if="selectedUser" class="form-group mt-3">
                    <label for="collegue">Procene: </label>
                    <select name="collegue" id="collegue" v-model="selectedProcenaName" @change="onChangeProcena()">
                        <option v-for="procena in selectedUserAllProcenasCanView" :key="procena.name">{{ procena.name }}
                        </option>
                    </select>
                </div>
            </div>

        </div>
        <div v-if="activeProcena">


            <div class="buttonWrapper1">

                <button class="pdfButtons" type="button" name="button" @click="preparePdf = true"
                    v-if="!preparePdf">Pripremi za pravljenje PDF-a</button>
                <button class="pdfButtons" type="button" name="button" @click="preparePdf = false" v-if="preparePdf">Vrati
                    na standardni prikaz</button>
                <button class="pdfButtons1" type="button" name="button" @click="makePDFProcena()" v-if="preparePdf">Napravi
                    pdf procene</button>

            </div>

            <div v-if="!preparePdf" class="h-100 pb-5">
                <div id="">
                    <div class="centered">
                        <h3><b>Procena Kandidata</b></h3>
                    </div>

                    <div class="wrap1 text-start">
                        <p class="ps-2">Legenda: 1 - Osnovno, 2 - Srednje, 3 - Odlicno</p>
                        <div class="container d-flex justify-content-start">
                            <div class="text-start">
                                <p><b>Ime kandidata:</b> {{ selectedUser }}</p>
                                <p><b>Godiste:</b> {{ year }}</p>
                                <p><b>Prebivaliste:</b> {{ location }}</p>
                                <p><b>Pozicija za koju konkuriše:</b> {{ position }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="total > 0">
                    <div  class="ocena pt-2 text-start">
                        <h3>Ukupna Ocena: {{ total }} ({{ totalPercent }}%)</h3>
                    </div>
                    <div class="question2 text-start">
                        <h5>Legenda: 1 - izuzetno loše, 2 - nezadovoljavajuće, 3 - zadovoljavajuće, 4 - vrlo dobro, 5 -
                            odlično</h5>
                    </div>
                </div>

                </div>
                <div v-for="(item, i) in activeProcena.questions" :key="i" class="questions">
                    <!-- COMBO -->
                    <div class="pt-1 text-start" v-if="item.isComboAnswer">
                        <h3> {{ item.questionText }} </h3>
                        <div v-for="(answ, i) in item.isCombo.isChecked" :key="i">
                            <p v-if="answ.checked" class="p-0 m-0 text-start"><b>Ocena - </b>{{ i + 1 }}</p>
                        </div>
                        <p style="text-align: left;">
                            <span style="white-space: pre-line">
                                <b>Komentar: </b>{{ item.isCombo.text }}
                            </span>
                        </p>
                    </div>
                    <!-- SINGLE CHECK -->
                    <div class="pt-1 text-start" v-if="item.isSingleCheck">
                        <h3>{{ item.questionText }}</h3>
                        <div v-for="(answ, i) in item.isChecked" :key="i">
                            <p v-if="answ.checked" class="p-0 m-0 text-start"><b>Ocena - </b>{{ i + 1 }}</p>
                        </div>
                    </div>
                    <!-- TEXT AREA -->
                    <div class="pt-1 text-start" v-if="item.isTextAnswer">
                        <h3>{{ item.questionText }}</h3>
                        <p style="text-align: left;">
                            <span style="white-space: pre-line">
                                <b>Komentar: </b>{{ item.textAnswer }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="preparePdf" id="procena">
                <div id="feedBackHeader">
                    <div class="centered">
                        <h3><b>Procena Kandidata</b></h3>
                    </div>

                    <div class="wrap">
                        <p>Legenda: 1 - Osnovno, 2 - Srednje, 3 - Odlicno</p>
                        <div class="container d-flex justify-content-center">
                            <div class="">
                                <p><b>Ime kandidata:</b> {{ user.name }}</p>
                                <p><b>Godiste:</b> {{ year }}</p>
                                <p><b>Prebivaliste:</b> {{ location }}</p>
                                <p><b>Pozicija za koju konkuriše:</b> {{ position }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="total > 0" class="questionAlt pt-2">
                        <h3>Ukupna Ocena: {{ total }} ({{ totalPercent }}%)</h3>
                    </div>

                </div>
                <div v-for="(item, i) in activeProcena.questions" :key="i" class="question">
                    <!-- COMBO -->
                    <div class="pt-1" v-if="item.isComboAnswer">
                        <h3> {{ item.questionText }} </h3>
                        <div v-for="(answ, i) in item.isCombo.isChecked" :key="i">
                            <p v-if="answ.checked" class="p-0 m-0 text-start"><b>Ocena - </b>{{ i + 1 }}</p>
                        </div>
                        <p style="text-align: left;">
                            <span style="white-space: pre-line">
                                <b>Komentar: </b>{{ item.isCombo.text }}
                            </span>
                        </p>
                    </div>
                    <!-- SINGLE CHECK -->
                    <div class="pt-1" v-if="item.isSingleCheck">
                        <h3>{{ item.questionText }}</h3>
                        <div v-for="(answ, i) in item.isChecked" :key="i">
                            <p v-if="answ.checked" class="p-0 m-0 text-start"><b>Ocena - </b>{{ i + 1 }}</p>
                        </div>
                    </div>
                    <!-- TEXT AREA -->
                    <div class="pt-1" v-if="item.isTextAnswer">
                        <h3>{{ item.questionText }}</h3>
                        <p style="text-align: left;">
                            <span style="white-space: pre-line">
                                <b>Komentar: </b>{{ item.textAnswer }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<style scoped>
.glavni {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

.buttonWrapper1 {
    margin: 15px auto;
    text-align: center;
}

.pdfButtons1 {
    background-color: #4C50AF;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        ". .";
    align-content: space-evenly;
}

.wrap1 {
    border-color: black;
    border-style: solid;
    border-width: thin;
    padding: 15px;
}

.ocena {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8CFF90;
    ;
    padding-left: 20px;

}

.questions:nth-child(even) {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8CFF90;
    ;
    padding-left: 20px;

}

.questions:nth-child(odd) {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8C90FF;
    padding-left: 20px;

}

.question2 {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8C90FF;
    padding-left: 20px;

}

.question:nth-child(even) {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8CFF90;
    ;
    padding-left: 20px;

    height: 250px;
    width: 810px;
    margin-left: 15px;
}

.question:nth-child(odd) {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8C90FF;
    padding-left: 20px;

    height: 250px;
    width: 810px;
    margin-left: 15px;
    margin-bottom: 60px;
}

#feedBackHeader {
    background-color: #ccccff;
    border-style: solid;
    border-color: black;
    border-width: medium;
    margin: 15px;
    padding: 15px;
    width: 810px;
    height: 570px;
    text-align: center;
    margin-bottom: 60px;
}

.wrap {
    border-color: black;
    border-style: solid;
    border-width: thin;
    padding: 15px;

    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: thin;
    padding: 15px;
}

.questionAlt {
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: thin;

    background-color: #8C90FF;
    padding-left: 20px;

    height: 250px;
}

.pdfButtons {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;

}</style>